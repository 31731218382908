import { $, listen } from "../util";

const openClass = 'is-open';
const animatingClass = 'animating';

function mobileNav() {
  const nav = $(".main-nav__nav-list");
  const toggle = $(".main-nav__toggle");


  if (!nav || !toggle) {
    console.error("Navigation elements not found");
    return;
  }
  toggle.ariaLabel = `Open menu`;

  function toggleNavClass() {
    const isOpen = !nav.classList.contains(openClass);
    toggle.textContent = isOpen ? "Close" : "Menu";
    toggle.ariaExpanded = isOpen;
    toggle.ariaLabel = `${isOpen ? 'Close' : 'Open'} menu`;
    nav.classList.add(animatingClass);
    nav.classList.toggle(openClass);

    //prevents menu from auto-opening
    nav.addEventListener('transitionend', () => {nav.classList.remove(animatingClass)}, {once: true});
  }

  listen("click", toggle, toggleNavClass);
}

export default mobileNav;
