import { $$, listen } from "../util";

function faqs() {
  const faqItems = $$(".faqs__item");

  // console.log(faqItems);

  faqItems.forEach((faqItem) => {
    const faqHeader = faqItem.querySelector(".faqs__header");
    const faqContent = faqItem.querySelector(".faqs__content");
    const faqIcon = faqHeader.querySelector(".faqs__header-icon");

    // Contract the FAQ item by default
    faqItem.setAttribute("aria-expanded", "false");
    faqContent.hidden = true;
    faqIcon.textContent = "+";

    function toggleFaq() {
      const isExpanded = faqItem.getAttribute("aria-expanded") === "true";

      faqItem.setAttribute("aria-expanded", !isExpanded);
      faqContent.hidden = isExpanded;
      faqIcon.textContent = isExpanded ? "+" : "-";
    }

    listen("click", faqHeader, toggleFaq);
  });
}

export default faqs;
