import { $, $$, listen } from "../util";

function projectContentUpload() {
  const wrappers = $$(".project-content-upload");

  wrappers.forEach((wrapper) => {
    const header = $(".project-content-upload__header", wrapper);
    const content = $(".project-content-upload__content", wrapper);
    const toggle = $(".project-content-upload__toggle", wrapper);

    // Select the existing divs
    const imageDiv = $(".project-content-upload__content-image", wrapper);
    const videoDiv = $(".project-content-upload__content-video", wrapper);
    const audioDiv = $(".project-content-upload__content-audio", wrapper);

    // Create a select dropdown
    const select = document.createElement("select");
    select.innerHTML = `
      <option value="">Select upload type...</option>
      <option value="image">Image</option>
      <option value="video">Video</option>
      <option value="audio">Audio</option>
    `;

    // Hide all divs by default
    imageDiv.style.display = "none";
    videoDiv.style.display = "none";
    audioDiv.style.display = "none";

    // Append the select after the first p tag
    const firstPTag = $("h4", wrapper);
    firstPTag.insertAdjacentElement("afterend", select);

    header.classList.add("uploadHeader-closed");
    content.classList.add("upload-closed");
    toggle.textContent = "+";

    listen("click", header, (event) => {
      // Check if the event target is the header itself
      if (event.target !== header) return;

      if (content.classList.contains("upload-closed")) {
        header.classList.replace("uploadHeader-closed", "uploadHeader-open");
        content.classList.replace("upload-closed", "upload-open");
        toggle.textContent = "-";
      } else {
        header.classList.replace("uploadHeader-open", "uploadHeader-closed");
        content.classList.replace("upload-open", "upload-closed");
        toggle.textContent = "+";
      }
    });

    // Listen for changes on the select dropdown
    listen("change", select, (event) => {
      // Stop the click event from bubbling up to the header
      event.stopPropagation();

      // Hide all divs
      imageDiv.style.display = "none";
      videoDiv.style.display = "none";
      audioDiv.style.display = "none";

      // Show the div corresponding to the selected option
      switch (event.target.value) {
        case "image":
          imageDiv.style.display = "block";
          break;
        case "video":
          videoDiv.style.display = "block";
          break;
        case "audio":
          audioDiv.style.display = "block";
          break;
      }
    });
  });
}

export default projectContentUpload;
