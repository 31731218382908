import { $ } from "../util";
import Plyr from "plyr";

function videoPlayer() {
  // const player = $("#player");

  // console.log(player);

  // const player = new Plyr("#player");
  const players = Plyr.setup(".js-player");
}

export default videoPlayer;
