// Let's bring in our utility functions. They're super helpful! 🧰
import { $, listen } from "../util";

// Here's our function for handling project asset uploads 📤
function projectAssetUpload() {
  // We're going to listen for any changes on our asset type dropdown. When it changes, we'll do something cool! 🎧🔄
  listen("change", "#assetType", function (event) {
    // First, let's grab our asset upload form. It's like our canvas for uploading assets! 🎨
    var form = $("#assetUploadForm");

    // We'll clear the form to make way for our new inputs. Out with the old, in with the new! 🔄
    form.innerHTML = "";

    // Now, let's see what type of asset we're dealing with. It's like opening a present! 🎁
    var type = event.target.value; // Use event.target.value instead of this.value

    // If it's an image, we'll add 10 image file inputs. Say cheese! 📸
    if (type === "image") {
      for (var i = 0; i < 10; i++) {
        form.innerHTML +=
          '<input type="file" name="fields[projectAssets][]" accept="image/*" required>';
      }
    }
    // If it's audio, we'll add 3 audio file inputs. Let's make some noise! 🎵
    else if (type === "audio") {
      for (var i = 0; i < 3; i++) {
        form.innerHTML +=
          '<input type="file" name="fields[projectAssets][]" accept="audio/mp3" required>';
      }
    }
    // If it's a video, we'll add 3 video file inputs and 3 text inputs for Vimeo/YouTube links. Lights, camera, action! 🎬
    else if (type === "video") {
      for (var i = 0; i < 3; i++) {
        form.innerHTML +=
          '<input type="text" name="fields[projectAssets][]" placeholder="Vimeo/YouTube link" required>';
        form.innerHTML +=
          '<input type="file" name="fields[projectAssets][]" accept="video/*" required>';
      }
    }
  });
}

export default projectAssetUpload;
