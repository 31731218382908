// Let's bring in our utility functions. They're super helpful! 🧰
import { $, listen } from "../util";

// Here's our function for updating the submission title. It's like a name tag for our submission! 🏷️
function submissionTitleUpdater() {
  // First, let's grab our first name, last name, and title input fields. It's like meeting new friends! 👋
  const firstNameInput = $("#firstName");
  const lastNameInput = $("#lastName");
  const titleInput = $("#title");

  // Here's our function for updating the title. It's like a little makeover for our title! 💅
  function updateTitle() {
    // We'll set the value of the title input to be the first name input value plus the last name input value. It's like a little name sandwich! 🥪
    titleInput.value = `${firstNameInput.value} ${lastNameInput.value}`;
  }

  // Now we'll listen for any input events on the first name and last name input fields. When we hear one, we'll update the title. It's like our title has ears! 👂
  listen("input", firstNameInput, updateTitle);
  listen("input", lastNameInput, updateTitle);
}

export default submissionTitleUpdater;
