import { $$, listen } from "../util";

function sensitiveToggle() {
  const sensitiveToggle = $$(".sensitive__toggle")[0];

  if (!localStorage.getItem("showSensitive")) {
    localStorage.setItem("showSensitive", "false");
  }

  if (localStorage.getItem("showSensitive") === "true") {
    document.body.classList.add("show_sensitive");
    sensitiveToggle.innerText = "Hide sensitive content"; // change the text when sensitive content is shown
  } else {
    sensitiveToggle.innerText = "Show sensitive content"; // change the text when sensitive content is hidden
  }

  listen("click", sensitiveToggle, () => {
    const showSensitive = localStorage.getItem("showSensitive") === "true";
    localStorage.setItem("showSensitive", showSensitive ? "false" : "true");

    if (showSensitive) {
      document.body.classList.remove("show_sensitive");
      sensitiveToggle.innerText = "Show sensitive content"; // change the text when sensitive content is hidden
    } else {
      document.body.classList.add("show_sensitive");
      sensitiveToggle.innerText = "Hide sensitive content"; // change the text when sensitive content is shown
    }
  });
}

export default sensitiveToggle;
