export function listen(type, element, callback) {
  document.addEventListener(type, (event) => {
    let target = event.target;

    while (target) {
      if (element.contains(target)) {
        callback(event, target);
        break;
      }
      target = target.parentElement;
    }
  });
}
