import colourPalette from "./components/colour-palette";
import countdown from "./components/countdown";
import faqs from "./components/faqs";
import locationMap from "./components/location-map";
import mobileNav from "./components/mobile-nav";
import projectAssetUpload from "./components/project-asset-upload";
import projectContentUpload from "./components/project-content-upload";
import sensitiveToggle from "./components/sensitive-toggle";
import studyLevelSelector from "./components/study-level-selector";
import submissionChecklist from "./components/submission-checklist";
import submissionTitleUpdater from "./components/submission-title-updater";
import videoPlayer from "./components/video-player";

const componentsMap = {
  "colour-palette": colourPalette,
  countdown: countdown,
  faqs: faqs,
  "location-map": locationMap,
  "mobile-nav": mobileNav,
  "project-asset-upload": projectAssetUpload,
  "project-content-upload": projectContentUpload,
  "sensitive-toggle": sensitiveToggle,
  "study-level-selector": studyLevelSelector,
  "submission-checklist": submissionChecklist,
  "submission-title-updater": submissionTitleUpdater,
  "video-player": videoPlayer,
};

document.addEventListener("DOMContentLoaded", () => {
  const components = document.querySelectorAll("[data-component]");

  components.forEach((component) => {
    const componentName = component.getAttribute("data-component");
    const Component = componentsMap[componentName];

    if (Component) {
      console.log("📦 Loaded", componentName);
      Component(); // Use the component here
    }
  });
});
