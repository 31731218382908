import { $, listen } from "../util";

function countdown() {
  const countdown = $("#countdown");
  countdown.innerHTML = "<span class='countdown__loading'>Loading...</span>";

  const date = new Date(countdown.dataset.date).getTime();

  const calculateCountdown = () => {
    const now = new Date().getTime();
    const distance = date - now;

    if (distance < 0) {
      clearInterval(interval);
      countdown.innerHTML = "EXPIRED";
      return;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));

    countdown.innerHTML = `<span class='countdown__days'>${days}</span><span class='countdown__suffix'>days</span>`;
  };

  // Call the function once on load
  calculateCountdown();

  // Then set it to run every 24 hours
  const interval = setInterval(calculateCountdown, 1000 * 60 * 60 * 24);
}

export default countdown;
