import { $, $$, listen } from "../util";

function studyLevelSelector() {
  const studyLevelBtns = $$(".random-profiles__selector-button");

  // Ensure studyLevelBtns is a NodeList or an array
  if (studyLevelBtns && studyLevelBtns.length > 0) {
    const studyLevels = Array.from(studyLevelBtns).map((btn) =>
      btn.getAttribute("data-selector")
    );
    console.log(studyLevels);

    // Set default (all, fdn, ug, pg) selector
    const defaultSelector = "pg";

    const defaultBtn = Array.from(studyLevelBtns).find(
      (btn) => btn.getAttribute("data-selector") === defaultSelector
    );
    if (defaultBtn) {
      defaultBtn.classList.add("selected");
      const defaultDiv = $(`.random-profiles__${defaultSelector}`);
      if (defaultDiv) {
        // Hide all divs except the default one
        const allDivs = $$(
          ".random-profiles__fdn, .random-profiles__ug, .random-profiles__pg, .random-profiles__all"
        );
        allDivs.forEach((div) => div.classList.add("hidden"));
        defaultDiv.classList.remove("hidden");
      }
    }

    studyLevelBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        // Hide all divs
        const allDivs = $$(
          ".random-profiles__fdn, .random-profiles__ug, .random-profiles__pg, .random-profiles__all"
        );
        allDivs.forEach((div) => div.classList.add("hidden"));

        // Remove "selected" state from all buttons
        studyLevelBtns.forEach((button) => button.classList.remove("selected"));

        // Show the corresponding div and add "selected" state to the clicked button
        const selector = btn.getAttribute("data-selector");
        const targetDiv = $(`.random-profiles__${selector}`);
        if (targetDiv) {
          targetDiv.classList.remove("hidden");
        }
        btn.classList.add("selected");
      });
    });
  } else {
    console.error(
      "No buttons found with the class 'random-profiles__selector-button'"
    );
  }
}

export default studyLevelSelector;
