import { $$ } from "../util";

function locationMap() {
  const locationElements = $$(".location-map__location");

  const locations = locationElements.map((locationElement) => {
    const name = locationElement.querySelector(
      ".location-map__location-title"
    ).textContent;
    const iconText = locationElement.dataset.icontext;
    const color = locationElement.dataset.iconcolour;
    const coordinates = JSON.parse("[" + locationElement.dataset.latlong + "]");
    const address = locationElement.querySelector(
      ".location-map__location-address"
    ).textContent;
    const link = locationElement.querySelector(
      ".location-map__location-link"
    ).href;

    return {
      name,
      iconText,
      color,
      coordinates,
      location: name,
      address,
      link,
    };
  });

  const arrayOfLatLngs = locations.map((location) => location.coordinates);
  const bounds = new L.LatLngBounds(arrayOfLatLngs);
  const map = L.map("location-map__map").setView([53.761984, -2.707254], 16);

  L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
    {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: "abcd",
      maxZoom: 20,
      minZoom: 16,
    }
  ).addTo(map);

  locations.forEach((location) => {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="15" fill="${location.color}" />
      <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="12px" dy=".3em" font-family="Montserrat" font-weight="bold">${location.iconText}</text>
    </svg>`;

    const icon = L.divIcon({
      className: "custom-icon",
      iconSize: [40, 40],
      html: svg,
    });

    const marker = L.marker(location.coordinates, { icon }).addTo(map);
    const popupContent = `<div style="font-family: Montserrat; display: flex; flex-direction: column; gap: 0.35rem;">
      <div style="display: flex; flex-direction: row; align-items: center; gap: 1rem;">
        ${svg}
        <h2 style="font-weight: bold; font-size: 1.35rem; margin: 0; padding: 0;">${location.location}</h2>
      </div>
      <div>
        <p style="font-weight: regular; color: #2F3235;">${location.address}</p>
        <a href="${location.link}" target="_blank" style="font-weight: 500;">Get directions</a>
      </div>
    </div>`;
    marker.bindPopup(popupContent);
  });

  map.fitBounds(bounds);
}

export default locationMap;
