import { $$, listen } from "../util";
import ColorThief from "../../../node_modules/colorthief/dist/color-thief.mjs";

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function colourPalette() {
  const images = $$(".colourImage");

  if (!images.length) {
    console.error("No images found for color extraction");
    return;
  }

  const colorThief = new ColorThief();

  images.forEach((image) => {
    const container =
      image.closest(".card-profile") ||
      image.closest(".profile") ||
      image.closest(".card__colour");

    if (container) {
      // Set the highlight and highlight-fade variables to a default color
      container.style.setProperty("--highlight", `rgba(238, 238, 238, 0.2)`);
      container.style.setProperty(
        "--highlight-fade",
        `rgba(238, 238, 238, 0.1)`
      );

      const extractColor = debounce(() => {
        const dominantColor = colorThief.getColor(image);
        container.dataset.highlight = `rgb(${dominantColor})`;
        container.style.setProperty("--highlight", `rgba(${dominantColor}, 1)`);
        container.style.setProperty(
          "--highlight-fade",
          `rgba(${dominantColor}, 0.18)`
        );
      }, 200); // Adjust the wait time as needed

      // Make sure image is finished loading
      if (image.complete) {
        extractColor();
      } else {
        image.addEventListener("load", extractColor);
      }
    }
  });
}

export default colourPalette;
