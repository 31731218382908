import { $, $$, listen } from "../util";

function submissionChecklist() {
  let readyToSubmit = false;
  const submitButton = $("#submit-button");
  const checklistItems = $$(".checklist-item__box");
  const checklistCountDiv = $(".submission-checklist__count");

  function toggleCheckedState(item) {
    item.classList.toggle("checklist-item__checked");
  }

  function updateChecklistCount() {
    const checkedItemsCount = Array.from(checklistItems).filter((item) => {
      const checkbox = item.querySelector("input[type='checkbox']");
      return checkbox.checked;
    }).length;

    const mainNav = $(".main-nav");
    const mainNavHeight = mainNav.offsetHeight;

    checklistCountDiv.setAttribute("data-count", checkedItemsCount);
    checklistCountDiv.style.setProperty(
      "--main-nav-height",
      `${mainNavHeight}px`
    );
    checklistCountDiv.innerHTML = `${checkedItemsCount} out of ${checklistItems.length} items checked`;
  }

  function toggleSubmitButtonState() {
    const remainingItemsCount = Array.from(checklistItems).filter((item) => {
      const checkbox = item.querySelector("input[type='checkbox']");
      return !checkbox.checked;
    }).length;

    readyToSubmit = remainingItemsCount === 0;

    if (readyToSubmit) {
      submitButton.classList.remove("btn__disabled");
      submitButton.textContent = "I'm ready to submit my profile";
    } else {
      submitButton.classList.add("btn__disabled");
      submitButton.textContent = `${remainingItemsCount} items remaining`;
    }
  }

  checklistItems.forEach((item) => {
    const checkbox = item.querySelector("input[type='checkbox']");

    if (checkbox.checked) {
      item
        .closest(".checklist-item__box")
        .classList.add("checklist-item__checked");
    }

    listen("change", checkbox, () => {
      toggleCheckedState(item);
      toggleSubmitButtonState();
      updateChecklistCount();
    });
  });

  toggleSubmitButtonState();
  updateChecklistCount();

  listen(
    "change",
    ".checklist-item__box input[type='checkbox']",
    (event, target) => {
      const submitButton = $("#submit-button");
      const checkboxes = $$(".checklist-item__box input[type='checkbox']");
      const allChecked = Array.from(checkboxes).every((c) => c.checked);

      if (allChecked) {
        submitButton.classList.remove("btn__disabled");
      } else {
        submitButton.classList.add("btn__disabled");
      }

      updateChecklistCount();
    }
  );
}

export default submissionChecklist;
